<template>
    <div>
        <div class="creating-success">
            <img :src="require('../../../../assets/images/admin/success.png')" alt="">
            <div class="text">保存成功</div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn" type="primary">返回列表</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            prevBtn() {
                this.$router.push('/practice/dataCenter')
            }
        }
    }
</script>

<style scoped lang="scss">
    .creating-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        img {
            width: 240px;
        }
        .text {
            color: #333333;
            font-size: 24px;
            line-height: 1;
            margin: 32px 0 36px;
        }
    }
    .bottom-btn {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 50px;
    }
</style>